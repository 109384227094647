<template>
    <side-panel class="chat-group-details d-flex flex-column">
        <div class="position-sticky company-title p-3">
            <div class="d-flex flex-row justify-content-between">
                <div class="mr-1">
                    <router-link class="btn btn-outline-dark border-0 rounded-0"
                                 to="/chats">
                        <font-awesome-icon icon="arrow-left"></font-awesome-icon>
                    </router-link>
                </div>
                <h3 class="flex-grow-1 font-weight-bolder mb-3">
                    {{ name }}
                </h3>
            </div>
            <div class="description">
                <label class="font-weight-bolder mb-1">
                    Description
                </label>
                <p class="mb-0 text-dark"
                   v-bind:class="{ 'expanded': isDescriptionExpanded }">
                    {{ description }}
                </p>
                <div class="d-flex justify-content-end">
                    <button class="btn btn-sm btn-link"
                            v-on:click="isDescriptionExpanded = !isDescriptionExpanded">
                        {{ isDescriptionExpanded ? "Collapse" : "Expand" }}
                    </button>
                </div>
            </div>
        </div>
        <div class="position-relative flex-grow-1 chat-content overflow-auto">
            <div class="position-relative messages whatsapp h-100">
                <message v-for="message in messages"
                         v-bind:key="message._id"
                         v-bind:message="message"
                         v-bind:type="type">
                </message>
            </div>
            <div class="input-wrapper position-absolute d-flex p-2">
                <b-form-textarea class="comment-input shadow rounded-pill border-0 mr-2"
                                 row="1"
                                 placeholder="✉️ Type a message"
                                 v-bind:disabled="isSending"
                                 v-model="message">
                </b-form-textarea>
                <button type="button"
                        class="btn btn-light text-primary shadow rounded-circle border-0"
                        v-bind:class="{ 'btn-loading': isSending }"
                        v-on:click="sendMessage">
                    <font-awesome-icon icon="paper-plane"></font-awesome-icon>
                </button>
            </div>
        </div>
    </side-panel>
</template>

<script>
import { getMessagesInChat, sendMessage, } from "@/workers/whatsapp.worker";
import { BFormTextarea } from "bootstrap-vue";
import {getUsers} from "@/workers/users.worker";
export default {
    name: "ChatDetails",
    components: {
        BFormTextarea,
        SidePanel: () => import("../SidePanel"),
        Message: () => import("./Message"),
    },
    props: {
        "chatId": {
            type: String,
        },
    },
    data () {
        return {
            intervalId: null,

            isLoading: false,
            isDescriptionExpanded: false,
            messages: [],

            isSending: false,
            message: "",
        };
    },
    computed: {
        chat () {
            return this.$store.getters["whatsapp/chatById"](this.chatId);
        },
        vendorId () {
            return this.chat?.vendorId ?? "";
        },
        type () {
            return this.chat?.type ? this.chat?.type : "whatsapp";
        },
        name () {
            return this.chat?.name ?? "";
        },
        description () {
            return this.chat?.desc ?? "";
        },
    },
    methods: {
        async sendMessage () {
            try {
                this.isSending = true;
                await sendMessage(this.vendorId, this.message);
                this.messages.push({
                    "_id": `local_${ Math.round(Math.random() * 10000) }`,
                    "mimeType": "text/plain",
                    "numberId": "60abc66a90ebbac510062e36",
                    "createdAt": new Date().toISOString(),
                    "timestamp": new Date().toISOString(),
                    "type": "chat",
                    "updatedAt": new Date().toISOString(),
                    "isGroupMsg": true,
                    "body": this.message,
                    "caption": "",
                    "isSelf": true,
                });
                this.message = "";
            } catch (e) {
                console.error(e);
            } finally {
                this.isSending = false;
            }
        },
        async loadMessages () {
            try {
                this.messages = await getMessagesInChat(this.chatId);
                this.messages.reverse();
            } catch (e) {
                console.error(e);
            }
        },
    },
    mounted () {
        this.loadMessages();
        this.intervalId = setInterval(
            async () => {
                try {
                    this.messages = await getMessagesInChat(this.chatId);
                    this.messages.reverse();
                } catch (e) {
                    console.error(e);
                    throw e;
                }
            },
            10 * 1000
        );
    },
    watch: {
        chatId () {
            this.loadMessages();
        },
    },
    beforeRouteLeave (to, from, next) {
        clearInterval(this.intervalId);
        next();
    },
}
</script>

<style lang="scss" scoped>
.chat-group-details {
    border-radius: 15px;
    overflow-y: auto;

    .description {
        & > p {
            max-height: 100px;
            overflow-x: hidden;
            overflow-y: hidden;
            text-overflow: ellipsis;
            white-space: pre-line;
            transition: max-height 500ms ease;

            &.expanded {
                max-height: 350px;
                overflow-y: auto;
            }
        }
    }

    .chat-content {
        .messages {
            padding-left: 12px;
            padding-bottom: 4rem;
            padding-right: 12px;

            letter-spacing: normal;
            outline: none;

            overflow: auto;

            &.whatsapp {
                background-image: url("../../assets/whatsapp_bg.png");
                background-repeat: repeat;
                background-size: cover;
            }
        }
    }
}

.input-wrapper {
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;

    & > .comment-input {
        height: calc(1.5em + 0.75rem + 2px);
    }
}
</style>
